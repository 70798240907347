<template>
  <v-container
    :class="`fill-height ${
      $vuetify.breakpoint.mdAndUp ? 'px-6' : ''
    } align-start`"
  >
    <v-card tile flat max-width="1000" class="mx-auto mt-5">
      <NewsTip
        position="bottom"
        name="cn-9544"
        :title="$t('understood.cn_9544.title')"
        :content="$t('understood.cn_9544.content')"
        :start-date="SPRINT_120_2ND_WEEK_START"
        :order="2"
        content-class="mb-2 ml-3"
      >
        <v-btn
          @click="cancel('Voltar')"
          outlined
          dark
          class="primary--text text-none"
          small
        >
          <v-icon v-text="'ph-arrow-left'" left /> {{ $t("common.back") }}
        </v-btn>
      </NewsTip>

      <v-card-title class="text-h5 font-weight-medium">
        Criar novo modelo de assinatura

        <v-spacer />
      </v-card-title>

      <v-form
        style="width: 100%"
        v-model="isValid"
        :disabled="loading"
        @submit.prevent=""
      >
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="pa-0">
            <v-subheader class="font-weight-medium">
              Selecione o editor que você deseja usar

              <span class="ml-1">
                <TooltipIcon
                  color="info"
                  label="Alguns editores suportam a edição de uma mesma assinatura entre múltiplos editores"
                  max-width="300"
                  icon="ph-question"
                  size="18"
                />
              </span>
            </v-subheader>

            <v-item-group
              v-model="newTemplate.editor"
              class="ma-0 pa-0"
              mandatory
              :disabled="loading"
            >
              <v-row class="ma-0 pa-0">
                <v-col
                  v-for="(editorType, index) in templateEditors"
                  :key="index"
                  cols="12"
                  md="4"
                >
                  <v-item v-slot="{ active, toggle }" :value="editorType.value">
                    <v-card
                      :color="active ? 'primary' : ''"
                      height="280"
                      class="pa-2"
                      :disabled="editorType.disabled || loading"
                      hover
                      outlined
                      @click="toggle"
                    >
                      <v-img contain :src="skeletonEditors[editorType.value]" />

                      <v-card-title
                        :class="`mb-0 pb-2 text-wrap break-word px-4 text-body-1 font-weight-medium  ${
                          active ? 'white--text' : 'primary--text'
                        }`"
                      >
                        {{ editorType.name }}
                      </v-card-title>
                      <v-subheader
                        :class="`${
                          active ? 'white--text' : 'primary--text'
                        } text-wrap break-word px-4 text-body-2 normal-line-height`"
                      >
                        {{ editorType.description }}
                      </v-subheader>

                      <v-card-actions class="px-3">
                        <v-chip
                          v-if="editorType.label"
                          label
                          x-small
                          :color="editorType.label_color"
                          text
                          class="text-caption"
                          dark
                        >
                          {{ editorType.label }}
                        </v-chip>

                        <v-spacer />

                        <v-icon
                          v-if="active"
                          v-text="'ph-fill ph-check-circle'"
                          color="white"
                          size="20"
                        />
                      </v-card-actions>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-col>

          <v-col cols="12" v-if="newTemplate.editor !== 'drag_and_drop'">
            <HtmlImporter
              :loading="loading"
              @update:html="newTemplate.html = $event"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-footer color="transparent" class="pt-6">
        <v-row class="ma-0 pa-0" justify-space-between no-gutters>
          <v-col>
            <v-btn
              large
              text
              color="secondary lighten-2"
              class="text-none text-body-1 font-weight-medium px-0"
              :disabled="loading"
              @click="cancel('Cancelar')"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-end">
            <NewsTip
              name="cn-9541"
              :title="$t('understood.cn_9541.title')"
              :content="$t('understood.cn_9541.content')"
              :start-date="SPRINT_120_2ND_WEEK_START"
              next-tip-name="cn-9544"
            >
              <v-btn
                :disabled="!isValid"
                large
                color="accent"
                :loading="loading"
                class="text-none text-body-1 font-weight-medium px-5"
                @click="createSignatureTemplate"
              >
                Criar modelo

                <v-icon v-text="'ph-arrow-right'" class="next-icon-1" right />
              </v-btn>
            </NewsTip>
          </v-col>
        </v-row>
      </v-footer>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { messages, rules, template } from "@/helpers/variables/templates";
import { getAnalytics, logEvent } from "firebase/analytics";
import { hasChanges } from "@/helpers/services/utils";
import { SPRINT_120_2ND_WEEK_START } from "@/helpers/variables/dates";

import HtmlImporter from "@/components/sign/signature/new_editor/HtmlImporter.vue";

export default {
  name: "SelectEditorType",

  components: { HtmlImporter },

  data() {
    return {
      showDescriptionField: false,
      savePreferences: true,
      selectedEditor: "rich_text",
      showRedirectDialog: false,
      timer: 5,
      timerInterval: null,
      messages,
      rules,
      template,
      isValid: true,
      newTemplate: {
        name: "Modelo sem título",
        html: "",
        editor: "rich_text",
      },
      defaultTemplateSettings: {
        name: "",
        html: "",
        editor: "rich_text",
      },
      skeletonEditors: {
        drag_and_drop: require("@/assets/images/SignEditorDragAndDrop.png"),
        rich_text: require("@/assets/images/SignEditorRichText.png"),
        html: require("@/assets/images/SignEditorHtml.png"),
      },
      editorNames: {
        drag_and_drop: "Editor Arrasta & Solta",
        rich_text: "Editor Rich Text",
        html: "Editor HTML",
      },
      SPRINT_120_2ND_WEEK_START,
    };
  },

  computed: {
    ...mapGetters([
      "isDev",
      "token",
      "loading",
      "currentUser",
      "mainDomain",
      "isConecta",
    ]),

    isRichTextEditor() {
      return this.newTemplate.editor === "rich_text";
    },

    templateHasChanges() {
      return hasChanges(this.newTemplate, this.defaultTemplateSettings);
    },

    templateEditors() {
      return [
        {
          value: "rich_text",
          name: "Editor Rich Text",
          description:
            "Utilize um editor estilo documento para criar sua assinatura",
          label: "Recomendado",
          label_color: "deep-purple lighten-1",
          disabled: false,
        },
        {
          value: "drag_and_drop",
          name: "Editor Arrasta & Solta",
          description:
            "Utilize blocos de conteúdo pré-definidos para agilizar sua criação",
          label: "Beta",
          label_color: "green",
        },
        {
          value: "html",
          name: "Editor HTML",
          description:
            "Escreva seu próprio modelo personalizado utilizando html",
          label: "Beta",
          label_color: "green",
        },
      ];
    },
  },

  watch: {
    "newTemplate.editor"() {
      if (this.newTemplate.editor) {
        const analytics = getAnalytics();

        logEvent(analytics, "choose_template_editor", {
          click: "Selecionou um modelo de editor",
          main_domain: this.mainDomain,
          email: this.currentUser.email,
          description: `Selecionou o modelo ${
            this.editorNames[this.newTemplate.editor]
          } durante a criação do modelo de assinatura`,
        });
      }
    },
  },

  methods: {
    ...mapMutations(["setSidebarMini", "setLoading", "setSnackBar"]),
    ...mapActions(["completeMission"]),

    async createSignatureTemplate() {
      this.setLoading(true);

      let url = process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template";

      const { name, editor, html } = this.newTemplate;

      let params = {
        name: name,
        editor: editor,
        html: html,
      };

      let auth = { headers: { Authorization: this.token } };

      await this.$axios
        .post(url, params, auth)
        .then(({ data }) => {
          const { key } = data;

          this.completeMission("create_template_model");

          if (editor === "rich_text") {
            this.$router.push(`/sign/email-signature/${key}`);
          } else {
            this.$router.push(`/sign/email-signature/${key}/edit`);
          }
        })
        .catch((error) => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: messages.error_add_template,
          });

          console.error("createSignatureTemplate(): ", error);
        });

      this.setLoading(false);
    },

    cancel(button_type) {
      const analytics = getAnalytics();

      logEvent(analytics, "cancel_template_creation", {
        click: button_type,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou no botão ${button_type} ${
          this.templateHasChanges
            ? "após ter tentado criar um modelo"
            : "sem ter tentado criar um modelo"
        }`,
      });

      this.$router.push({ name: "SignTemplates" });
    },
  },

  mounted() {
    this.setSidebarMini(true);
    this.setLoading(false);
  },
};
</script>
